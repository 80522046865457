<template>
  <v-row>
    <v-col cols="12">
      <v-chip
          v-for="(choice,index) in item.choices"
          :key="choice.id"
          @click:close="deleteChoice(index)"
          class="mr-2"
          close>
        {{choice.name}}
      </v-chip>
      <v-text-field
        label="Add a choice"
        v-model="new_choice.name"
        append-outer-icon="mdi-plus"
        @click:append-outer="addChoice"/>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      new_choice: {
        id: null,
        value: null
      }
    };
  },
  methods: {
    addChoice() {
      let new_choice = JSON.parse(JSON.stringify(this.new_choice));
      this.new_choice.id = this.uid();
      if (this.item.choices) {
        this.item.choices.push(new_choice);
      } else {
        this.item.choices = [new_choice];
      }
      this.new_choice = {
        id: null,
        value: null
      };
    },
    deleteChoice(index) {
      this.item.choices.splice(index, 1);
    },
    uid() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0, v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
  }
};
</script>