<template>
    <draggable
      class="drag-area disabled"
      handle=".handle"
      v-bind="dragOptions"
      tag="v-list"
      :list="items"
      :group="{ name: getGroup}"
      ghost-class="ghost">
      <div active-class="no-active" disabled v-for="(item) in items" :key="item.id">
        <v-list-item :style="{'border:1px solid grey': item.type === 'list'}">
          <v-list-item-icon class="handle">
            <v-icon>mdi-drag</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-text-field @change="setId(item)" dense :prepend-icon="item.icon" :single-line="true" v-model="item.label"/>
            <multiple-choice v-if="item.type === 'multiple-choice'" :item="item" />
          </v-list-item-content>
          <v-list-item-icon class="ml-5">
            <v-icon small @click="copyItem(item)" color="grey lighten-1">mdi-content-copy</v-icon>
          </v-list-item-icon>
          <v-list-item-icon class="ml-5">
            <v-icon small @click="editItem(item)" color="grey lighten-1">mdi-pencil</v-icon>
          </v-list-item-icon>
          <v-list-item-icon>
            <v-icon small @click="deleteItem(item.id)" color="grey lighten-1">mdi-delete</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-row>
          <form-tree-child
            v-if="item.type === 'section' || item.type === 'list'"
            :items="item.children"
            :parent="item"
            @delete="deleteItem"
            @copy="copyItem"
            @edit="editItem"
            class="col"/>
        </v-row>
      </div>
    </draggable>
</template>

<script>
import FormTree from "./FormTree";
import MultipleChoice from "./MultipleChoice";
import draggable from "vuedraggable";

export default {
  name: "form-tree-child",
  components: {
    "form-tree-child": FormTree,
    draggable,
    MultipleChoice
  },
  props: ["items", "parent"],
  methods: {
    copyItem(item) {
      this.$emit("copy", item);
    },
    editItem(item) {
      this.$emit("edit", item);
    },
    deleteItem(item_id) {
      this.$emit("delete", item_id);
    },
    setId(item) {
      item.id = item.label.replace(/\s+/g, "-").toLowerCase() + "#" + Math.floor(10 + Math.random() * 90)
    },
    uid() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0, v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "fields",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    getGroup() {
      if (this.parent == null || (this.parent && (this.parent.type === "section" || this.parent.type === "list"))) {
        return "fields";
      }
      return false;
    },
  }
};
</script>

<style scoped>
.indent {
  margin-left: 25px;
}
.ghost {
  height: 50px;
  width: 100%;
  background-color: azure;
}

.drag-area {
  min-height: 20px;
  margin: 20px;
  padding: 3px !important;
  border: 1px dashed #efefef;
  border-radius: none !important;
}

.v-list-item {
  /* border-top: 1px solid lightgrey; */
  /* border-radius: 5px; */
}

.no-active {
  background: white;
}

.handle {
  cursor: pointer;
}
</style>