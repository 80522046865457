<template>
  <v-row>
    <v-col>
      <editor
        :key="key"
        ref="text_editor"
        api-key="6pd16nn5jw8fqh5aayva56pirbe9c17rlgtbs6mpx6n81ly5"
        :init="init"
        v-model="new_value"
        @input="$emit('input', new_value)"/>
    </v-col>
  </v-row>
</template>

<script>
//import Rte from "@tinymce/tinymce-vue";
import Editor from "@tinymce/tinymce-vue";
// import css from "./editor.css";
export default {
  props: [
    "value",
    "height",
    "label",
    "module_fields",
    "margins",
    "background_url",
  ],
  components: {
    editor: Editor,
  },

  data() {
    return {
      new_value: "",
      show: false,
      search: null,
      key: 0,
    };
  },
  methods: {
    load() {
      this.new_value = this.getValue;
      this.show = true;
      this.key = this.key + 1;
    },
  },
  computed: {
    getValue() {
      return this.value;
    },
    paddingTop() {
      return this.margins ? this.margins.margin_top : 0;
    },
    paddingBottom() {
      return this.margins ? this.margins.margin_bottom : 0;
    },
    paddingLeft() {
      return this.margins ? this.margins.margin_left : 0;
    },
    paddingRight() {
      return this.margins ? this.margins.margin_right : 0;
    },
    backgroundUrl() {
      return this.background_url ? this.background_url : "";
    },
    init() {
      return {
        language: "en",
        height: "1200px",
        inline: false,
        menubar: true,
        content_css: "document",
        paste_data_images: true,
        content_style:
          "body { line-height: 1.4; padding-top:" +
          this.paddingTop +
          "mm;background-repeat: no-repeat;background-size: 820px 1160px;padding-bottom:" +
          this.paddingBottom +
          "mm;padding-left:" +
          this.paddingLeft +
          "mm;padding-right:" +
          this.paddingRight +
          "mm;} .pagebreak{height:" +
          this.paddingTop +
          "mm;}",
        toolbar_sticky: true,
        pagebreak_split_block: true,
        pagebreak_separator:
          "<pagebreak style='height:" + this.paddingTop + "mm;'/>",
        lineheight_formats: "0.4 0.5 0.6 0.7 0.8 0.9 1.0 1.1 1.2 1.3 1.4",
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen template",
          "insertdatetime media table paste code help wordcount pagebreak",
        ],
        toolbar:
          "undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | template | \
           bullist numlist outdent indent | removeformat | pagebreak | table",
      };
    },
  },
  watch: {
    value: {
      handler() {
        this.new_value = this.getValue;
        this.$emit("input", this.new_value);
      },
    },
    show: {
      handler() {
        this.new_value = this.getValue;
        this.$emit("input", this.new_value);
      },
    },
    paddingTop: {
      handler() {
        this.key = this.key + 1;
      },
    },
    paddingBottom: {
      handler() {
        this.key = this.key + 1;
      },
    },
    paddingLeft: {
      handler() {
        this.key = this.key + 1;
      },
    },
    paddingRight: {
      handler() {
        this.key = this.key + 1;
      },
    },
    backgroundUrl: {
      handler() {
        this.key = this.key + 1;
      },
    },
  },
};
</script>

<style>
.tox-notifications-container {
  display: none !important;
}
.tinymce-content p {
  padding: 0;
  margin: 2px 0;
}

.tox-tinymce--toolbar-sticky-on .tox-editor-header {
  top: 60px !important;
}

.pagebreak {
  background: #f4f4f4;
}
</style>