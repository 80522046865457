<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name" :current="record.name"/>
    <v-row>
      <v-col>
        <base-tab-panel
            :module_name="page.name"
            :record="record"
            @change="save()"
            @archive="archive()"
            :loading="loading"
            :model="page.model">
          <template v-slot:main_fields>
            <template v-for="field in usual_fields">
              <base-field
                  :isDisabled="!canEdit(record.id || 0)"
                  :key="field.name"
                  :value="record[field.name]"
                  :field="field"
                  @change="usualFieldDataChanged"/>
            </template>
            <base-tags v-if="tags_required" :isDisabled="!canEdit(record.id || 0)" v-model="record.tags" :modal_name="page.name"/>
          </template>
          <template v-if="custom_fields.length > 0" v-slot:module_fields>
            <template v-if="record.custom_fields">
              <template v-for="field in custom_fields">
                <base-field
                    :isDisabled="!canEdit(record.id || 0)"
                    :key="field.name"
                    :value="record.custom_fields[field.name]"
                    :field="field"
                    @change="customFieldDataChanged"/>
              </template>
            </template>
          </template>
        </base-tab-panel>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-tabs v-model="tab">
              <v-tab>{{ $store.getters.translate("report_editor") }}</v-tab>
              <v-tab>{{ $store.getters.translate("form_editor") }}r</v-tab>
              <v-tab @click="preview()">{{ $store.getters.translate("preview") }}</v-tab>
              <v-tab-item>
                <v-row>
                  <v-col cols="2">
                    <v-card flat>
                      <v-card-text>
                        <div>
                          <h4>{{ $store.getters.translate('fieldsets') }}</h4>
                          <v-treeview
                              transition
                              item-key="key"
                              dense
                              :items="template_fields"
                              item-text="label">
                            <template v-slot:label="{ item }">
                              <a @click="insertContent(item.key)">{{ $store.getters.translate(item.label) }}</a>
                            </template>
                          </v-treeview>
                          <h4>{{ $store.getters.translate('reportsections') }}</h4>
                          <v-treeview
                              transition
                              item-key="id"
                              dense
                              return-object
                              :items="templates"
                              item-text="name">
                            <template v-slot:label="{ item }">
                              <a @click="insertTemplate(item)">{{ $store.getters.translate(item.name) }}</a>
                            </template>
                          </v-treeview>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-divider vertical/>
                  <v-col>
                    <v-card flat>
                      <v-card-text>
                        <editor
                            ref="rte_main"
                            @preview="preview()"
                            :label="$store.getters.translate('content')"
                            v-model="record.html"
                            :module_fields="template_fields"
                            style="height: 100%"
                            :margins="margins"
                            class="mb-5"/>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <v-row>
                  <v-col cols="2">
                    <v-card flat class="sticky">
                      <v-card-text>
                        <field-types />
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-divider vertical/>
                  <v-col>
                    <v-card flat>
                      <v-card-text v-if="record.content">
                        <p v-if="record.content.length === 0" class="text-center text--secondary">{{ $store.getters.translate("empty_form") }}</p>
                        <form-tree :items="record.content" @delete="deleteItem" @copy="copyItem" @edit="editItem" :parent="null"/>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <v-card flat style="height: 100%">
                  <v-card-text style="height: 100%">
                    <v-layout
                        v-if="!preview_url"
                        align-center
                        justify-center
                        column
                        fill-height>
                      <v-flex row align-center>
                        <div class="justify-center">
                          <v-progress-circular :size="100" color="primary" indeterminate/>
                          <transition name="fade">
                            <h3 class="mt-5">{{ $store.getters.translate("loading") }}</h3>
                          </transition>
                        </div>
                      </v-flex>
                    </v-layout>
                    <p v-else align="center" style="height: 100%" class="mt-5">
                      <iframe
                          ref="preview"
                          style="overflow: hidden; height: 1000px; width: 100%"
                          :src="preview_url"
                          frameborder="0"/>
                    </p>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <BaseDraggableModal
        :name="modal_name"
        max-height="80vh"
        width="70vw"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        :ref="modal_name">
      <v-card>
        <BaseModal>
          <v-toolbar slot="header" class="draggable_selector" dark color="primary" dense flat>
          <v-btn icon @click="closeEditItemDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $store.getters.translate('update') }} {{ edited_item.label }}</v-toolbar-title>
          <v-spacer/>
          <v-btn text small @click="saveItem()">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-toolbar>
          <div slot="content">
            <v-divider/>
            <v-card-text class="pt-5">
              <v-text-field label="Label" v-model="edited_item.label"/>
              <v-select
                  :items="template_fields"
                  v-model="edited_item.placeholder"
                  item-text="label"
                  item-value="id"
                  :label="$store.getters.translate('placeholder')"
                  hint="If this field is to be automatically filled. What should be the value?"
                  persistent-hint/>
            </v-card-text>
          </div>
        </BaseModal>
      </v-card>
    </BaseDraggableModal>
  </v-container>
</template>

<script>
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import BaseTabPanel from "../../components/commonComponents/BaseTabPanel";
import FieldTypes from "../../components/commonComponents/FieldTypes";
import FormTree from "../../components/commonComponents/FormTree";
import Editor from "../templates/editor/Edit";
import helpFunctions from "../../plugins/helpFunctions";
import deleteFromArray from "../../plugins/deleteFromArray";
import lodash from "lodash";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";

export default {
  components: {
    BaseBreadcrumb,
    BaseTabPanel,
    FieldTypes,
    FormTree,
    Editor,
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      modal_name: 'show_edit_item',
      page: {
        title: this.$store.getters.translate("forms"),
        name: "forms",
        model: "form",
      },
      record: {
        content: [],
      },
      template_fields: [],
      templates: [],
      usual_fields: [],
      custom_fields: [],
      tags_required: false,
      loading: false,
      margins: {
        margin_top: 20,
        margin_bottom: 20,
        margin_left: 20,
        margin_right: 20,
      },
      tab: 0,
      preview_url: null,
      edited_item: {},
    };
  },
  created() {
    this.load();
    this.getTemplates();
    this.template_fields = helpFunctions.template_fields;
    this.getTemplateFields();
  },
  methods: {
    ...helpFunctions.modal_functions,
    async load() {
      this.loading = true;
      this.record = await helpFunctions.loadModel(this.$toasted, this.page.name, this.$route.params.id);
      await this.getFields();
    },
    async save() {
      if(!this.loading) {
        if (helpFunctions.modelFieldsFilled(this.$toasted, this.record, this.usual_fields)) {
          this.loading = true;
          if(await helpFunctions.saveModel(this.$toasted, this.page.name, this.record)) {
            await this.load();
          }
          this.loading = false;
        }
      }
    },
    copyItem(item) {
      var new_item = lodash.cloneDeep(item);
      new_item.id = this.uid();
      this.record.content.push(new_item);
    },
    editItem(item) {
      this.edited_item = item;
      this.openModal(this.modal_name);
    },
    deleteItem(item_id) {
      this.record.content = this.record.content.filter(item => item.id != item_id);
      deleteFromArray.find(this.record.content, item_id);
    },
    saveItem() {
      this.closeModal(this.modal_name);
      this.save();
    },
    uid() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0, v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
    closeEditItemDialog() {
      this.closeModal(this.modal_name);
    },
    async archive() {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then(async (res) => {
        if (res) {
          this.loading = true;
          if (await helpFunctions.deleteModel(this.$toasted, this.page.name, this.record.id)) {
            await this.$router.push("/" + this.page.name);
          }
          this.loading = false;
        }
      });
    },
    async getTemplateFields() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/fieldsets")
          .then((response) => {
            response.data.data.forEach(fieldset => {
              this.template_fields.push({
                label: fieldset.name,
                key: fieldset.key,
                children: JSON.parse(fieldset.content)
              });
            });
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    async getTemplates() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/reportsections")
          .then((response) => {
            this.templates = response.data.data;
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    preview() {
      this.save();
      this.record.model = this.page.name;

      var recordForSending = this.record;
      recordForSending.margin_left = 10;
      recordForSending.margin_right = 10;
      recordForSending.margin_top = 40;
      recordForSending.header = helpFunctions.html_header;

      this.$http
          .post(this.$store.getters.appUrl + "v2/pdf/preview", recordForSending, {})
          .then((response) => {
            this.preview_url = response.data;
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    insertContent(key) {
      this.$refs.rte_main.$refs.text_editor.editor.insertContent("{{" + key.toLowerCase() + "}}");
    },
    insertTemplate(template) {
      this.$refs.rte_main.$refs.text_editor.editor.execCommand("mceInsertTemplate", false, template.html);
    },
    usualFieldDataChanged(data) {
      this.record[data[0]] = data[1];
    },
    customFieldDataChanged(data) {
      this.record.custom_fields[data[0]] = data[1];
    },
    async getFields() {
      this.loading = true;
      const response = await helpFunctions.getFields(this.page.model, "show_on_update");
      this.tags_required = response.tags_required;
      this.usual_fields = response.usual_fields;
      this.custom_fields = response.custom_fields;
      this.loading = false;
    },
    canEdit(id = 0) {
      return this.$can("edit", this.$createEntity(this.page.model, {id}));
    },
  },
};
</script>