<template>
    <draggable
      tag="v-list"
      :list="form_fields"
      :group="{ name: 'fields', pull: 'clone', put: false }"
      :clone="clone"
      @start="drag_action = true"
      @end="drag_action = false">
      <template v-for="(field,index) in form_fields">
        <v-list-item :key="index">
          <v-list-item-icon>
            <v-icon>{{field.icon}}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="field.label"/>
          </v-list-item-content>
        </v-list-item>
      </template>
    </draggable>
</template>

<script>
import draggable from "vuedraggable";
export default {
  components: {
    draggable
  },
  data() {
    return {
      form_fields: [
        {
          id: this.uid(),
          label: "List",
          placeholder: "attributes",
          type: "list",
          properties: [],
          children: [],
          rules: [],
          icon: "mdi-format-list-bulleted"
        },
        {
          id: this.uid(),
          label: "Text field",
          placeholder: "",
          type: "text-field",
          properties: [],
          children: [],
          rules: [],
          icon: "mdi-format-text"
        },
        {
          id: this.uid(),
          label: "Text area",
          placeholder: "",
          type: "text-area",
          properties: [],
          children: [],
          rules: [],
          icon: "mdi-message-text"
        },
        {
          id: this.uid(),
          label: "Multiple choice",
          placeholder: "",
          type: "multiple-choice",
          choices: [],
          properties: [],
          children: [],
          rules: [],
          icon: "mdi-checkbox-multiple-marked-outline"
        },
        {
          id: this.uid(),
          label: "Signature",
          placeholder: "",
          type: "signature",
          properties: [],
          children: [],
          rules: [],
          icon: "mdi-pen"
        }
      ]
    };
  },
  methods: {
    clone(item) {
      let cloned_item = JSON.parse(JSON.stringify(item));
      cloned_item = {
        id: item.label.replace(/\s+/g, "-").toLowerCase() + "#" + Math.floor(10 + Math.random() * 90),
        label: item.label.toLowerCase(),
        type: item.type,
        properties: item.properties,
        children: item.children,
        rules: item.rules,
        icon: item.icon
      };

      return cloned_item;
    },
    uid() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0, v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
  }
};
</script>

<style>
</style>