const deleteFromArray = {
    find(items, item_id) {
        items.forEach(item => {
            let child_found = item.children.find(child => child.id == item_id)
            if (child_found) {
                item.children = item.children.filter(del_child => del_child.id != item_id)
            } else {
                deleteFromArray.find(item.children, item_id)
            }
        })
    }
}

export default deleteFromArray